.panel--submission{
    position: relative;
    .panel__heading{
        padding: 0;
        h1 , h6{
            display: block;
            margin: 0 auto;
        }
        h1{
            font-weight: 500;
            font-size: 48px;
            line-height: 58px;
            max-width: 435px;
            color: #121212;
        }
    }
    .panel__body{
        margin-top: 72px;
    }
    .btn.btn--back{
        position: absolute;
        top: 12px; left: -95px;
        svg{
            width: 23px;
        }
    }
}
.page-content--login {
    margin-left: auto;
        margin-right: auto;
        padding-left: 20px;
        padding-right: 20px;
}

.page-content--login__body {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    font-size: 32px;
    margin-top: 100px;
    font-weight: 700;
}

.login-form {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}
.page-content--login__body a {
    color: #AE64AC;
    padding: 24px;
}
.btn--login {
    padding: 12px;
    background: rgb(221, 221, 221);
    border: 2px gray solid;
    border-radius: 10px;
}
.panel--submission-buttons .panel__body{
    display: flex;
    align-items: center;
    .btn--forwards{
        margin-left: auto;
        background-color: #000000;
        span{
            color: white;
        }
    }
    .btn--submission{
        height: 60px;
        line-height: 60px;
        padding: 20px 24px;
        border-radius: 30px;
        span{
            font-size: 20px;
            line-height: 20px;
            display: inline-block;
        }
        &:disabled{
            opacity: 0.3;
        }
    }
}

.currenct-sections-container{
    display: flex;
    align-items: center;
    justify-content: center;
}

.currenct-section{
    padding: 20px 24px;
    background-color: #F2F2F2;
    border-radius: 30px;
    pointer-events: none;
    user-select: none;
    & , span{
        display: inline-block;
    }
    span{
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
    }
    &.currenct-section--current{
        background-color: #000000;
        color: white;
    }
    &.currenct-section--prev{
        background-color: #AE64AC;
        color: white;
    }
}

.currenct-section-separator{
    width: 108px;
    height: 1px;
    background-color: #000000;
}

.submission-section{
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    margin-top: 72px;
    &.submission-section--4{
        gap: 32px;
    }
}

.form-group{
    position: relative;
    transition: 200ms;
    padding: 28px 0;
    &.form-group--filled{
        .form-group__small{
            display: block;
        }
        .form-group__info{
            margin-bottom: 0;
            visibility: visible;
            opacity: 1;
        }
        .form-group__error{
            margin-top: 0;
        }
    }
    &.form-group--fail{
        margin-top: 0;
        .form-group__error{
            visibility: visible;
            opacity: 1;
        }
    }
    &.form-group--success{
        .form-group__error{
            margin-top: -36px;
        }
    }
    .form-group__info{
        visibility: hidden;
        opacity: 0;
        display: flex;
        align-items: center;
        padding: 4px 24px;
        z-index: 2;
        margin-bottom: -32px;
        transition: 200ms;
    }
    .form-group__small--characters{
        margin-left: auto;
    }
    .form-group__small , .form-group__small--characters{
        color: #767676;
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
    }
    .form-group__error{
        position: relative;
        display: flex;
        align-items: center;
        padding: 8px 28px;
        margin-top: -36px;
        transition: 200ms;
        visibility: hidden;
        opacity:0;
        svg{
            margin-right: 12px;
        }
        .error-message{
            font-size: 20px;
            font-weight: 400;
            color: #D43636;
        }
    }
    input , select , textarea{
        background-color: #F5F5F5;
        border: 0px;
        border-radius: 12px;
        padding: 20px 24px;
        appearance: none;
        outline: none;
        width: 100%;
        font-size: 20px;
        position: relative;
        z-index: 3;
    }
    input , select{
        height: 64px;
        &::placeholder{
            color: #121212;
            font-weight: 600;
        }
    }
    select{
        color: #121212;
        font-weight: 600;
        cursor: pointer;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 60px;
    }
    option:first-of-type{
        display: none;
    }
    .svg-dd{
        position: absolute;
        top: 0; bottom: 0; right: 30px;
        margin: auto 0;
        z-index: 4;
        pointer-events: none;
        user-select: none;
    }
    textarea{
        font-family: 'Inter', sans-serif !important;
        font-weight: 600;
        color: #121212;
        min-height: 268px;
        resize: none;
    }
}

.form-group--success{
    input , select , textarea{
        border: 3px solid #048621 !important;
    }
    .form-group__small , .form-group__small--characters{
        color: #767676 !important;
    }
}

.form-group__select-container{
    display: flex;
    flex-direction: column;
    position: relative;
    .form-group__add-select{
        margin-left: auto;
        margin-top: 24px;
        height: 40px;
        span{
            font-size: 16px;
            line-height: 19px;
            display: inline-block;
        }
    }
}

.select-additional__category , .select-additional__category-btn{
    display: flex;
    align-items: center;
}

.select-additional__category-btn{
    svg{
        width: 10px;
        flex: 0 0 10px;
    }
    span{
        white-space: nowrap;
    }
}

.select-additional__category{
    margin-top: 24px;
    gap: 10px;
    flex-wrap: wrap;
}

.form-group--select{
    &.form-group--fail{
        .form-group__error{
            margin-top: 0;
        }
    }
    &.form-group--success{
        .form-group__error{
            margin-top: -40px;
        }
        .form-group__info{
            margin-bottom: 0;
        }
    }
}


// .form-group__select-container{
//     position: relative;
// }

// .form-group__select-container:not(:first-of-type){
//     margin-top: 36px; 
// }

.submission-section__subsection{
    .submission-section__subheading{
        font-size: 20px;
        font-weight: 600;
        color: #121212;
        padding: 0 24px;
    }
    .submission-section__subbody{
        padding: 20px 24px;
    }
        .submission-section__subbody img {
            width: 100%
        }
}

.submission-section__subbody.tags-container{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    .tag{
        border: 1px solid #5A5A5A;
        padding: 7px 24px;
        border-radius: 24px;
        font-size: 14px;
        font-weight: 400;
        color: #5A5A5A;
    }
}

.panel--submission.panel--submission--submitted{
    .panel__heading{
        h1{
            max-width: 581px;
        }
        h6{
            font-size: 24px;
            line-height: 29px;
            color: #121212;
            font-weight: 400;
            max-width: 581px;
            text-align: center;
            margin-top: 36px;
            b{
                font-weight: 700;
            }
        }
    }
}

.panel--submission-buttons.panel--submission-buttons--submitted{
    margin-top: 430px;
}

.panel--submission-buttons--submitted{
    .panel__body{
        justify-content: center;
    }
}

.change-story-container{
    display: flex;
    flex-direction: column;
    >span , a{
        text-align: center;
        font-size: 24px;
        line-height: 29px;
    }
    >span{
        max-width: 467px;
        color: #767676;
        display: block;
    }
    a{
        display: inline-block;
        margin-top: 11px;
        color: #AE64AC;
        text-decoration: none;
        position: relative;
        &::before{
            content: "";
            display: inline-block;
            position: absolute;
            bottom: 0; left: 0; right: 0;
            margin: 0 auto;
            height: 2px;
            width: 136px;
            background-color: #AE64AC;
        }
    }
}

.summary-error{
    border: 2px solid #D43636;
    padding: 24px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    svg{
        animation: 2s linear 1s Breathing infinite;
    }
    span{
        margin-left: 20px;
        font-weight: 600;
        font-size: 20px;
        color: #D43636;
    }
}

@media(max-width: 1200px){
    .page-content{
        padding-top: 124px;
    }
}

@media(max-width: 1100px){
    .panel--submission{
        .btn.btn--back{
            top: 0; left: 0;
        }
        .panel__body{
            margin-top: 50px;
        }
    }
    .submission-section{
        margin-top: 42px;
        gap: 0;
    }
    .currenct-section{
        padding: 16px 20px;
        span{
            font-size: 17px;
        }
    }
    .currenct-section-separator{
        width: 50px;
    }
    .form-group{
        padding: 12px 0;
    }
    .select-additional__category{
        margin-top: 12px;
    }
    .panel--submission-buttons{
        margin-top: 48px;
    }
}

@media(min-width: 750px){
    .panel--submission--submitted{
        .doodle-container{
            display: none;
        }
    }
    .panel--submission-buttons--submitted{
        .btn.btn--home{
            display: none;
        }
    }
}

@media(max-width: 750px){
    .login-form {
            flex-direction: column;
            align-items: flex-start;
        }
    .page-content--submission{
        position: relative;
        height: 100%;
        + #ndzn-footer{
            display: none;
        }
        .page-content__inner , .container{
            height: 100%;
        }
    }
    .panel--submission.panel--submission--submitted {
        position: unset;
        padding-top: 120px;
        .doodle-container{
            position: absolute;
            pointer-events: none;
            user-select: none;
            img{
                width: 100%;
            }
        }
        .doodle-container--1{
            left: 0px;
            top: 116px;
            width: 83px;
        }
        .doodle-container--2{
            left: 0;
            bottom: 0;
            width: 108px;
        }
        .doodle-container--3{
            right: 0;
            bottom: 0;
            width: 94px;
        }
        .panel__heading{
            flex-direction: column;
            h1{
                margin-left: 0;
                max-width: 289px;
            }
            h6{
                display: block;
                width: 100%;
                font-size: 16px;
                line-height: 19px;
                margin-top: 24px;
            }
        }
        .btn.btn--back{
            display: none;
        }
    }
    .panel--submission-buttons.panel--submission-buttons--submitted{
        margin-top: 48px !important;   
        flex: 1 1 auto;
        .btn--home{
            width: 130px;
            margin: 0px auto auto;
            height: 43px;
            line-height: 42px;
            padding: 0 42px;
            &::before{
                display: none;
            }
            span{
                display: inline-block;
                font-size: 16px;
                font-weight: 600;
                line-height: 19px;
            }
        }
    }
    .change-story-container{
        > span , a{
            font-size: 14px;
            line-height: 17px;
        }
        a::before{
            width: 79px;
        }
        > span{
            display: block;
            margin-top: 48px;
        }
    }
    .panel--submission{
        .btn.btn--back{
            flex: 0 0 40px;
            height: 40px;
            width: 40px;
            top: -5px;
            position: unset;
            svg{
                width: 13px;
            }
        }
        .panel__heading{
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            h1{
                font-size: 24px;
                font-weight: 500;
                line-height: 29px;
                max-width: none;
                display: flex;
                align-items: center;
                margin: 0 0 0 12px;
            }
            h6{
                display: none;
            }
        }
        .panel__body{
            margin-top: 24px;
        }
    }
    .currenct-sections-container{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .currenct-section-separator{
        display: none;
    }
    .currenct-section{
        padding: 0;
        text-align: center;
        height: 3px;
        span{
            display: inline-block;
            font-size: 12px;
            line-height: 15px;
            color: #777777;
            position: relative;
            top: 3px;
        }
        &.currenct-section--prev{
            background-color: #121212;
            span{
                color: #121212;
            }
        }
        &.currenct-section--current{
            background-color: #AE64AC;
            span{
                color: #AE64AC;
            }
        }
    }
    .form-group{
        padding: 10px 0;
        input , select , textarea{
            font-size: 16px;
            font-weight: 500 !important;
            &::placeholder{
                font-weight: 500 !important;
            }
        }
        input , select{
            height: 43px;
            padding: 0px 16px;
        }
        textarea{
            padding: 16px;
            min-height: 128px;
            resize: vertical;
            overflow: hidden;
            &:focus{
                height: auto;
            }
            &::placeholder{
                color: #121212;
            }
        }
        .form-group__small , .form-group__small--characters{
            font-size: 14px;
            font-weight: 600;
            line-height: 17px;
            color: #D43636;
        }
        .form-group__error{
            padding: 8px 12px;
            .error-message{
                font-size: 14px;
                display: inline-block;
                line-height: 17px;
            }
        }
        .form-group__info{
            padding: 4px 12px;
            margin-bottom: -24px;
        }
        .svg-dd{
            right: 22px;
        }
    }
    .form-group--success{
        input , select , textarea{
            border-width: 2px !important;
        }
    }
    .select-additional__category{
        margin-top: 8px;
        gap: 8px;
    }
    .select-additional__category-btn{
        padding: 6px 14px;
        span{
            font-size: 12px;
        }
        svg{
            margin-right: 10px;
        }
    }
    .panel{
        &.panel--submission-buttons{
            margin-top: 48px;
            .panel__body .btn--submission{
                height: 44px;
                line-height: 44px;
                padding: 0 32px;
                border-radius: 16px;
                span{
                    font-size: 14px;
                    font-weight: 600;
                    margin-top: -2px;
                }
            }
        }
    }
    .submission-section__subsection{
        .submission-section__subheading , .submission-section__subbody{
            padding: 0;
        }
        .submission-section__subbody{
            margin-top: 12px;
            line-height: 24px;
            font-size: 16px;
        }
    }
    .submission-section__subbody.tags-container{
        gap: 10px;
        .tag{
            padding: 3px 24px;
        }
    }
}

@media(max-height: 750px){
    .panel--submission.panel--submission--submitted{
        padding-top: 0;
        .doodle-container{
            display: none;
        }
    }
}

@media(max-width: 600px) {
    .page-content--login {
        margin-left: auto;
        margin-right: auto
    }

    .page-content--login__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 24px;
        font-size: 32px;
        margin-top: 100px;
        font-weight: 700;
    }
}
@media(max-width: 390px){
    .panel--submission .panel__heading h1{
        font-size: 21px;
        font-weight: 600;
    }
    .submission-section__subsection{
        .submission-section__subheading{
            font-size: 18px;
        }
        .submission-section__subbody{
            margin-top: 8px;
        }
    }
    .submission-section.submission-section--4{
        gap: 24px;
    }
    .panel.panel--submission-buttons{
        margin-top: 36px;
    }
}

@keyframes Breathing {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.15);
    }
    50% {
        transform: scale(1);
    }
    75% {
        transform: scale(0.85);
    }
    100% {
        transform: scale(1);
    }
}