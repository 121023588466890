.container{
    padding: 0 var(--containerPadding);
    &.container--readStories{
        max-width: none;
        padding: 0px 120px 40px;
        position: relative;
        height: 528px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &.container--homepageIlluminated{
        max-width: none;
        background-color: #AE64AC;
    }
}

.homepage-section .homepage-section__read-stories{
    h1{
        margin: 0;
        color: #AE64AC;
        font-weight: 500;
        font-size: 64px;
        line-height: 78px;
    }
}

.homepage-section__read-stories{
    max-width: 934px;
    text-align: center;
    > .btn--black{
        height: 72px;
        border-radius: 12px;
        padding-left: 48px;
        padding-right: 48px;
        margin-top: 48px;
        margin: 48px auto 0;
        span{
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
        }
    }
}

.doodle-homepage-container{
    overflow: hidden;
    position: absolute;
}

.doodle-homepage-container--1{
    margin: auto 0;
    left: 0;
    img{
        width: 296px;
        display: inline-block;
        margin-left: -80px;
    }
}

.doodle-homepage-container--2{
    bottom: 23px;
    right: 0;
    img{
        width: 315px;
        display: inline-block;
        margin-right: -122px;
    }
}

.doodle-homepage-container--3{
    bottom: -125px; left: 139px;
    img{
        width: 260px;
        display: inline-block;
    }
}

.homepage-section__illuminated-stories{
    max-width: 823px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding-top: 66px;
    padding-bottom: 66px;
    h3 , .stories-counter{
        color: white;
    }
    h3{
        font-weight: 500;
        font-size: 36px;
        line-height: 44px;
    }
    .stories-counter{
        position: relative;
        margin-left: auto;
        pointer-events: none;
        user-select: none;
        > h1{
            font-weight: 700;
            font-size: 96px;
            line-height: 116px;
            display: inline-block;
            color: white;
        }
        h1.stories-counter__prevNumber , h1.stories-counter__nextNumber{
            font-size: 65px;
            position: absolute;
            line-height: 80px;
        }
        .stories-counter__prevNumber{
            transform: matrix(1, 0, -0.19, 0.98, 0, 0);
            top: -61px; right: 0;
            &::before{
                content: '';
                width: 100%;
                height: 80px;
                display: block;
                position: absolute;
                top: 12px;
                left: 0;
                background: linear-gradient(180deg, #AE64AC, transparent);
            }
        }
        .stories-counter__nextNumber{
            transform: matrix(1, 0, 0.18, 0.98, 0, 0);
            bottom: -61px; right: 0;
            &::before{
                content: '';
                width: 100%;
                height: 80px;
                display: block;
                position: absolute;
                bottom: 12px;
                left: 0;
                background: linear-gradient(180deg, transparent, #AE64AC);
            }
        }
    }
}

.panel--homepageCompressedArticles{
    .panel__heading{
        padding-top: 72px;
    }
    .panel__body{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 24px 47px;
    }
}

.panel.panel--homepageCategories{
    margin-top: 16px;
}

.panel--homepageCategories{
    .panel__body{
        display: flex;
        flex-wrap: wrap;
        gap: 12px 8px;
        .author:not(:first-of-type){
            margin-top: 0;
        }
        .author-skeleton:not(:first-of-type){
            margin-top: 0;
        }
    }
    .buttons-container--support{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
}

.authors--container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 48px;
}

.author{
    display: flex;
    align-items: center;
    &:not(:first-of-type){
        margin-top: 16px;
    }
    .author__image{
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }
    .author__name{
        margin-left: 8px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        display: inline-block;
    }
}

.buttons-container--support{
    gap: 9px;
    .btn{
        display: flex;
        align-items: center;
        padding: 10px 24px;
        span{
            font-size: 16px;
            font-weight: 600;
            display: inline-block;
            margin-top: -1px;
        }
    }
}

// TEMP
.doodle-homepage-container--mobile{
    display: none;
}
// 

@media(max-width: 1500px){
    .doodle-homepage-container--3{
        display: none;
    }
}

@media(max-width: 1200px){
    .doodle-homepage-container--1 img{
        margin-left: -80px;
        width: 200px;
    }
    .doodle-homepage-container--2 img{
        margin-right: -105px;
        width: 245px;
    }
    .page-content__inner--homepage{
        gap: 34px !important;
        padding: 0 24px;
    }
}

@media(max-width: 1050px){
    .homepage-section .homepage-section__read-stories h1{
        font-size: 46px;
        line-height: 56px;
    }
    .homepage-section__read-stories > .btn--black{
        height: 51px;
        line-height: 50px;
        padding: 0 32px;
        border-radius: 16px;
        span{
            font-size: 16px;
            font-weight: 500;
        }
    }
    .container.container--readStories{
        padding: 0px 150px 40px;
        height: 416px;
    }
    .homepage-section__illuminated-stories{
        padding: 50px 0;
        h3{
            font-size: 28px;
            line-height: 40px;
        }
        .stories-counter{
            > h1{
                font-size: 80px;
                line-height: 92px;
            }
            h1.stories-counter__prevNumber , h1.stories-counter__nextNumber{
                font-size: 52px;
                line-height: 62px;
            }
            .stories-counter__prevNumber , .stories-counter__nextNumber{
                right: 4px;
            }
            .stories-counter__prevNumber{
                top: -45px;
                &::before{
                    top: 9px;
                    height: 44px;
                }
            }
            .stories-counter__nextNumber{
                bottom: -45px;
                &::before{
                    bottom: 9px;
                    height: 44px;
                }
            }
        }
    }
}

@media(max-width: 950px){
    .page-content__inner.page-content__inner--homepage{
        gap: 24px !important;
        grid-template-columns: auto 260px;
        .page-sidebar{
            width: 260px;
            flex: 0 0 260px;
            .page-sidebar__section:not(:first-of-type){
                margin-top: 32px;
            }
        }
    }
}

@media(max-width: 900px){
    .panel{
        .panel__heading{
            padding-bottom: 24px;
        }
    }
    .page-content__inner.page-content__inner--homepage{
        .article__inner{
            padding-bottom: 24px;
        }
    }
    .page-sidebar__section--support .btn{
        padding: 8px 16px;
        span{
            font-size: 14px;
            margin-bottom: 0px;
        }
    }
}

@media(max-width: 800px){
    .article__main{
        .article__main__left{
            padding-right: 16px;
            .article__title{
                max-height: 40px;
                font-size: 16px;
                font-weight: 500;
            }
            p{
                font-size: 13px;
                line-height: 18px;
            }
        }
    }
    .article__tags{
        .article__tag{
            &:not(:first-of-type){
                display: none;
            }
        }
    }
    .article{
        .article__tags{
            gap: 12px;
        }
        .article__info{
            margin-top: 20px;
            .article__info__right > .btn--grey{
                display: none;
            }
        }
    }
}

@media(max-width: 750px){
    .doodle-homepage-container--1{
        display: none;
    }
    .doodle-homepage-container--2{
        bottom: -55px;
        img{
            margin-right: -59px;
            width: 194px;
        }
    }
    .doodle-homepage-container--mobile{
        display: block;
        position: absolute;
        top: -63px; left: 0;
        pointer-events: none;
        user-select: none;
        .doodle-homepage--mobile--1{
            width: 71px;
        }
    }
    .container.container--readStories{
        padding: 60px 24px 145px;
        height: unset;
    }
    .homepage-section{
        .homepage-section__read-stories{
            h1{
                text-align: left;
            }
            > .btn--black{
                margin-left: 0;
            }
        }
    }
    .homepage-section__illuminated-stories{
        padding: 38px 0 70px;
        flex-direction: column;
        h3{
            font-size: 20px;
            font-weight: 500;
        }
        .stories-counter{
            margin: 40px auto 0;
        }
    }
    .page-content__inner.page-content__inner--homepage{
        display: flex;
        flex-direction: column;
        gap: 33px !important;
    }
    .page-content__inner.page-content__inner--homepage .page-sidebar{
        width: 100%;
        flex: 1 1 auto
    }
    .page-sidebar{
        .page-sidebar__section__heading{
            font-size: 20px;
            font-weight: 500;
        }
        .page-sidebar__section__body{
            gap: 12px;
        }
    }
}

@media(max-width: 650px){
    .page-content__inner--landing .panel.panel--homepageCompressedArticles{
        margin-top: 24px;
    }
}

@media(max-width: 600px){
    .authors--container{
        grid-template-columns: 1fr;
        gap: 16px;
    }
}

@media(max-width: 540px){
    .homepage-section .homepage-section__read-stories h1{
        font-size: 40px;
        line-height: 48px;
    }
    .container.container--readStories{
        padding: 65px 24px 145px;
    }
    .doodle-homepage-container--2{
        bottom: -44px;
        img{
            width: 180px;
        }
    }
}

@media(max-width: 450px){
    .buttons-container--support .btn span{
        font-size: 14px;
    }
}

@media(max-width: 380px){
    .homepage-section__illmuinated-stories h3{
        line-height: 30px;
        margin: 10px 0;
        text-align: center;
    }
    .homepage-section .homepage-section__read-stories h1{
        font-size: 34px;
        line-height: 40px;
    }
    .homepage-section__read-stories > .btn--black{
        margin-top: 30px;
    }
    .container{
        &.container--readStories{
            padding-bottom: 115px;
        }
        &.container--homepageIlluminated{
            padding-left: 12px;
            padding-right: 12px;
        }
    }
}