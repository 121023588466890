.aboutus-section--grey{
    background-color: #F5F5F5;
}

.page-content h1{
    font-weight: 500;
    font-size: 48px;
    line-height: 58px;
    color: #AE64AC;
}

.container.container--aboutus{
    max-width: 955px;
}

.doodle{
    pointer-events: none;
    user-select: none;
}

.aboutus-section{
    position: relative;
    text-align: center;
    &.aboutus-section--top{
        padding-top: 270px;
        padding-bottom: 333px;
    }
    &.aboutus-section--mission{
        padding-top: 60px;
        padding-bottom: 144px;
    }
    &.aboutus-section--founder{
        padding-top: 60px;
        padding-bottom: 105px;
        .avatar-container{
            margin-bottom: 36px;
        }
        .btn--get-in-touch{
            padding: 24px 48px;
            margin: 48px auto 0;
            span{
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
            }
        }
    }
    &.aboutus-section--team{
        padding-top: 60px;
        padding-bottom: 124px;
    }
    &.aboutus-section--partners{
        padding-top: 80px;
        padding-bottom: 104px;
        .container{
            max-width: 1520px;
        }
    }
    &.aboutus-section--bottom{
        padding-top: 82px;
        padding-bottom: 144px;
        .container{
            max-width: 1070px;
        }
        .buttons-container{
            margin-top: 111px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 48px;
        }
        .btn{
            padding: 24px 48px;
            border-radius: 12px;
            span{
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                display: inline-block;
            }
        }
    }
    ul , ol{
        padding-left: 0;
    }
    ul{
        max-width: 740px;
        margin-left: auto;
        margin-right: auto;
        li{
            list-style-type: none;
            position: relative;
            span{
                position: relative;
                &::before{
                    content: '';
                    display: block;
                    position: absolute;
                    height: 6px; width: 6px;
                    top: 10px;
                    left: -18px;
                    background-color: #121212;
                    border-radius: 50%;
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }
        }
    }
    .btn--share-story{
        display: inline-block;
        margin-top: 48px;
        height: 72px;
        line-height: 72px;
        padding: 2px 48px;
        margin-left: auto;
        margin-right: auto;
        span{
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
        }
    }
}

.contributions-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 102px;
    margin-top: 161px;
}

.contribution{
    display: flex;
    align-items: center;
    span{
        font-weight: 500;
        font-size: 36px;
        line-height: 44px;
    }
    img{
        margin-right: 36px;
    }
}

.contribution--free-access{
    img{
        width: 106px;
    }
}
.contribution--research{
    img{
        width: 78px;
    }
}
.contribution--education{
    img{
        width: 120px;
    }
}

ul , ol{
    margin: 36px 0;
}

h1{
    text-align: center;
    position: relative;
    span{
        position: relative;
        z-index: 3;
    }
    .doodle--3 , .doodle--4{
        display: block;
        position: absolute;
        z-index: 2;
    }
    .doodle--3{
        width: 291px;
        top: 36px;
        left: 332px;
    }
    .doodle--4{
        width: 314px;
        bottom: 0; 
        right: 184px;
    }
}
h2{
   font-weight: 500;
   color: #AE64AC; 
   line-height: 44px;
   font-size: 36px;
   margin: 36px 0;
}
p , li{
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
}
p:not(:first-of-type){
    margin-top: 27px;
}

.avatar-container{
    &.avatar-container--large{
        width: 192px;
        height: 192px;
        overflow: hidden;
        border-radius: 50%;
        margin: 0 auto;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.team-container{
    display: flex;
    align-items: start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 36px 48px;
}

.team-el{
    max-width: 192px;
    .team-el__name , .team-el__position{
        font-size: 20px;
        line-height: 24px;
        color: #121212;
        display: block;
    }
    .team-el__name{
        font-weight: 600;
        margin-top: 36px;
    }
    .team-el__position{
        font-weight: 400;
    }
}

.partners-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 120px;
    flex-direction: row;
}

.partner-el{
    .partner-el__name , .partner-el__position{
        font-size: 20px;
        line-height: 24px;
        display: block;
    }
    .partner-el__name{
        font-weight: 600;
        margin-top: 36px;
    }
    .partner-el__position{
        font-weight: 400;
    }
}

.doodle{
    position: absolute;
    &.doodle--1{
        top: 152px; left: 60px;
        width: 190px;
    }
    &.doodle--2 {
        top: 90px; right: 98px;
        width: 196px;
    }
    &.doodle--5{
        top: -125px; right: 0;
        width: 275px;
    }
    &.doodle--6{
        top: 30px; left: 37px;
        width: 267px;
    }
    &.doodle--7{
        top: 62px; right: 18px;
        width: 242px;
    }
    &.doodle--8{
        top: 253px; left: 0;
        width: 146px;
    }
    &.doodle--9{
        bottom: -13px; right: 0;
        width: 160px;
        z-index: 2;
    }
    &.doodle--10{
        top: 152px; left: 0;
        width: 210px;
    }
    &.doodle--11{
        bottom: 60px; right: 0;
        width: 150px;
    }
    &.doodle--12{
        bottom: -3px; left: 0; right: 0;
        margin: 0 auto;
        width: 425px;
    }
}

.btn--join-team{
    padding: 24px 48px;
    margin: 72px auto 0;
    span{
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
    }
}

@media(max-width: 1650px){
    .doodle{
        &.doodle--5{
            width: 205px;
        }
        &.doodle--6{
            width: 220px;
            top: -100px
        }
    }
}

@media(max-width: 1400px){
    .doodle{
        &.doodle--9{
            bottom: -100px;
        }
        &.doodle--10{
            top: -100px;
            width: 140px;
        }
    }
}

@media(max-width: 1300px){
    .doodle{
        &.doodle--1{
            top: 55px;
            left: 35px;
        }
        &.doodle--2{
            top: 54px;
            right: 25px;
        }
        &.doodle--8{
            top: 25px;
        }
        &.doodle--11{
            bottom: -70px;
        }
    }
}

@media(max-width: 1050px){
    .partners-container{
        gap: 80px;
    }
    .aboutus-section{
        &.aboutus-section--mission{
            padding-bottom: 72px;
            h2{
                margin-top: 0;
            }
        }
        &.aboutus-section--founder , &.aboutus-section--team , &.aboutus-section--partners{
            padding-top: 20px;
            padding-bottom: 72px;
        }
        &.aboutus-section--bottom{
            padding-top: 62px;
            padding-bottom: 72px;
            .buttons-container{
                margin-top: 72px;
            }
            h1{
                font-size: 32px;
                line-height: 39px;
                font-weight: 500;
                width: 342px;
                margin: 0 auto;
            }
        }
    }
    .doodle{
        &.doodle--1 , &.doodle--2{
            top: 40px;
            width: 140px;
        }
        &.doodle--1{
            left: -10px;
        }
        &.doodle--2{
            right: -10px;
        }
        &.doodle--5{
            width: 155px;
        }
        &.doodle--6{
            width: 170px;
            left: -50px;
        }
        &.doodle--7{
            width: 172px;
            top: -60px;
            right: 20px;
        }
        &.doodle--8{
            width: 112px;
            top: -80px;
        }
        &.doodle--12{
            width: 283px;
            bottom: 2px;
        }
    }
    .contributions-container{
        margin-top: 72px;
        gap: 50px;
        flex-direction: column;
    }
    .contribution{
        span{
            font-size: 24px;
            line-height: 29px;
            font-weight: 500;
        }
        img{
            margin-right: 24px;
        }
    }
    .contribution--free-access img{
        width: 68px;
    }
    .contribution--research img{
        width: 46px;
    }
    .contribution--education img{
        width: 77px;
    }
    .team-el{
        .team-el__name , .team-el__position{
            font-size: 16px;
            line-height: 19px;
        }
        .team-el__name{
            font-weight: 600;
            margin-top: 16px;
        }
        .team-el__position{
            font-weight: 500;
        }
    }
    .partner-el{
        .partner-el__name , .partner-el__position{
            font-size: 16px;
        }
        .partner-el__name{
            margin-top: 36px;
        }
        .partner-el__position{
            font-weight: 500;
        }
    }
}

@media(min-width: 1001px){
    .doodle-mobile{
        display: none;
    }
}

@media(max-width: 1000px){
    h1{
        .doodle--3 , .doodle--4{
            display: none;
        }
    }
    .page-content--aboutus .doodle{
        display: none !important;
    }
    .aboutus-section.aboutus-section--top{
        padding-top: 146px;
        padding-bottom: 207px;
        h1{
            font-size: 32px;
            font-weight: 500;
            line-height: 39px;
            margin: 0 auto;
            display: inline-block;
        }
    }
    .doodle-mobile{
        position: absolute;
        pointer-events: none;
        user-select: none;
    }
    .doodle-mobile--1{
        width: 104px;
        top: 24px; left: 256px;
    }
    .doodle-mobile--2{
        width: 86px;
        left: 363px;
        top: 24px;
    }
    .doodle-mobile--3{
        width: 223px;
        margin: 0 auto;
        bottom: -1px; left: 0; right: 0;
    }
    .doodle-mobile--4 , .doodle-mobile--5 , .doodle-mobile--6{
        z-index: 2;
    }
    .doodle-mobile--4{
        height: 138px;
        top: -10px; right: 0;
    }
    .doodle-mobile--5{
        height: 135px;
        bottom: 78px; left: 0;
    }
    .doodle-mobile--6{
        height: 111px;
        bottom: -55px; right: 40px;
 
    }
}

@media(max-width: 970px){
    .aboutus-section.aboutus-section--top h1{
        width: 342px;
        flex: 0 0 342px;
    }
    .doodle-mobile--1{
        left: 200px;
    }
    .doodle-mobile--2{
        left: 42px;
        top: 63px;
    }
}

@media(max-width: 950px){
    .aboutus-section{
        &.aboutus-section.aboutus-section--founder, &.aboutus-section--team, &.aboutus-section--partners{
            padding-top: 5px;
        }
        &.aboutus-section--founder .btn--get-in-touch{
            padding: 0 32px;
            height: 51px;
            line-height: 51px;
            border-radius: 16px;
            span{
                font-size: 16px;
                font-weight: 600;
            }
        }
        &.aboutus-section--bottom{
            padding-top: 48px;
            h1{
                width: auto;
            }
            .btn{
                padding: 0 32px;
                height: 56px;
                line-height: 56px;
                border-radius: 16px;
                span{
                    font-size: 16px;
                    font-weight: 600;
                }
            }
            .buttons-container{
                gap: 24px;
                margin-top: 36px;
            }
        }
        .btn--join-team{
            padding: 0 32px;
            height: 51px;
            line-height: 51px;
            border-radius: 16px;
            margin-top: 48px;
            span{
                font-size: 16px;
                line-height: 19px;
            }
        }
        h2{
            font-size: 32px;
            line-height: 39px;
        }
        p , li{
            font-size: 16px;
            line-height: 24px;
        }
        ul , ol{
            margin: 36px 0 48px;
            padding: 0 48px;
            text-align: left;
        }
        .btn--share-story{
            padding: 0 32px;
            height: 51px;
            line-height: 51px;
            border-radius: 16px;
            span{
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 0;
            }
        }
    }
    .partner-el{
        .partner-el__name{
            margin-top: 32px;
        }
        .partner-el__position{
            line-height: 18px;
        }
    }
    .partners-container{
        gap: 60px;
    }
    .contributions-container{
        margin-top: 36px;
        gap: 24px;
    }
}

@media(max-width: 650px){
    .aboutus-section{
        &.aboutus-section--top{
            padding-top: 130px;
            padding-bottom: 254px;
            .container{
                padding: 0;
            }
        }
        &.aboutus-section--bottom{
            .buttons-container{
                flex-direction: column;
                .btn{
                    min-width: 291px;
                    justify-content: center;
                }
                span{
                    margin: 0;
                }
            }
        }
        ul , ol{
            padding: 0 24px;
        }
        ul li span::before{
            top: 8px;
        }
        p , li{
            text-align: left;
        }
        &.aboutus-section--mission{
            padding-top: 48px;
        }
    }
    p:not(:first-of-type){
        margin-top: 24px;
    }
    .team-container{
        grid-template-columns: 1fr 1fr;
        padding: 0 50px;
    }
    .partners-container{
        gap: 36px;
    }
    .contributions-container{
        align-items: flex-start;
        padding: 0 24px;
    }
    .contribution span{
        text-align: left;
    }
}

@media(max-width: 420px){
    .team-container{
        padding: 0 16px;
    }
}

@media(max-width: 390px){
    .aboutus-section.aboutus-section--bottom .buttons-container .btn{
        width: 100%;
    }
    .contributions-container{
        padding: 0;
    }
}

@media(max-width: 375px){
    .aboutus-section{
        &.aboutus-section--top h1 {
            flex: 1 1 auto;
            width: auto;
            padding: 0 24px;
        }
        &.aboutus-section--bottom{
            .buttons-container .btn{
                padding: 0 20px;
                min-width: auto;
                svg{
                    display: none;
                }
            }
        }
    }
    .doodle-mobile--1 , .doodle-mobile--2 , .doodle-mobile--3{
        display: none;
    }
}