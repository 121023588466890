.btn{
    border: 0px solid transparent;
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 8px 24px;
    cursor: pointer;
    transition: 200ms;

    svg{
        margin-right: 8px;
    }
    span{
        font-weight: 600;
        display: inline-block;
        line-height: 17px;
        margin-bottom: -1px;
    }

    &.btn--pink{
        background-color: #AE64AC;
        &:hover , &:focus{
            background-color: #ba72b8;
        }
        svg{
            fill: white;
        }
        span{
            color: white;
        }
    }
    &.btn--grey{
        background-color: #F5F5F5;
        &:hover , &:focus{
            background-color: #e3e3e3;
        }
        svg{
            fill: #121212;
        }
        span{
            color: #121212;
        }
    }
    &.btn--black{
        background-color: #121212;
        &:hover , &:focus{
            background-color: #2f2f2f;
        }
        svg{
            fill: white;
        }
        span{
            color: white;
        }
    }
}



// Special buttons
.btn.btn--back{
    padding: 0;
    border-radius: 100%;
    flex: 0 0 76px;
    width: 76px;
    height: 76px;
    justify-content: center;
    position: absolute;
    left: -152px; top: 50px;
    svg{
        display: inline-block;
        margin-right: 0;
    }
    &:hover , &:focus{
        svg{
            animation: 2s linear infinite chevronAnimation;
        }
    }
}

.btn--get-in-touch , .btn--join-team{
    border-radius: 20px;
    display: inline-block;
}

.btn--get-in-touch{
    background-color: #F5F5F5;
    padding: 24px 48px;
    span{
        font-weight: 600;
        font-size: 20px;
        color: #121212;
    }
}
// 



.btn.btn--loading {
    position: relative;
    pointer-events: none;
    user-select: none;
    color: transparent;
    text-shadow: none !important;
    span , svg{
        text-shadow: none !important;
        opacity: 0;
        pointer-events: none;
        user-select: none;
    }
    &:after {
        --spinnerSize: 1.1em;
        content: '';
        left: calc(50% - (var(--spinnerSize) / 2));
        top: calc(50% - (var(--spinnerSize) / 1.6));
        position: absolute;
        animation: spinAround 0.5s infinite linear;
        border: 3px solid;
        border-color: transparent transparent white white;
        border-radius: 50%;
        display: block;
        height: var(--spinnerSize);
        width: var(--spinnerSize);
    }
    @keyframes spinAround {
        100% {
            transform: rotate(360deg);
        }
    }
}



@keyframes chevronAnimation {
    0%   {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-3px);
    }
    50%{
        transform: translateX(0px);
    }
    75%{
        transform: translateX(3px);
    }
    100% {
        transform: translateX(0px);
    }
}