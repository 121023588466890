.form-group__selectedImage{
    h6{
        font-size: 16px;
        font-weight: 500;
        margin: 10px 0;
    }
    img{
        width: 140px;
        height: 140px;
        object-fit: cover;
    }
}

.__modeButtons{
    display: flex;
    align-items: center;
    gap: 20px;
}

.form-group__assess-buttons{
    display: flex;
    align-items: center;
    .btn{
        padding: 0 30px;
        height: 40px;
        line-height: 40px;
        border-radius: 10px;
    }
    .form-group__left{
        margin-left: auto;
    }
    .form-group__right{
        margin-left: 10px;
    }
    .btn--black:disabled{
        pointer-events: none;
        user-select: none;
        opacity: 0.6;
    }
}

.form-group--assessArticle{
    > p{
        margin-top: 14px;
    }
}

.form-group__buttonSubgroup{
    display: flex;
    align-items: center;
    margin-top: 20px;
    > span{
        margin-left: 20px;
    }
}

.form-group--mode{
    display: flex;
    align-items: center;
    gap: 14px;
}

.page-content--assess-article{
    position: relative;
}

.form-group--searchId__button{
    margin-top: 24px;
}

.form-group--ArticleOptions{
    .form-group__options{
        display: flex;
        align-items: center;
        .btn{
            margin-left: 20px;
        }
    }
}

.form-group--findArticle{
    > .btn--black{
        margin-top: 28px;
    }
}

.deleteArticle-container{
    > .btn--black{
        margin-top: 28px;
    }
}

.assessArticle-container{
    margin-top: 80px;
}

.successful-message{
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 2px solid #04df47;
    padding: 20px;
    svg{
        flex: 0 0 30px;
        width: 30px;
        fill: #04df47;
        margin-right: 20px;
    }
}