// TEMP
// .article__author__avatar , .article__image , .author__image-container > img{
//     width: 50px;
// }
// 

.article .author__img , .article__image , .author__image-container img{
    width: 30px;
}

.page-content__inner--homepage{
    display: grid;
    grid-template-columns: auto 308px;
    max-width: 996px;
    margin: 0 auto;
    gap: 34px 64px;
}

.page-sidebar{
    flex: 0 0 308px;
    width: 308px;
}

.page-main{
    flex: 1 1 auto;
}

.author{
    .author__img__container{
        flex: 0 0 24px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        overflow: hidden;
        background-color: #F5F5F5;
    }
    .author__img{
        height: 100%;
        width: auto;
    }
    .author__name{
        font-size: 12px;
        font-weight: 500;
        color: #121212;
    }
}

.article{
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 35px;
    &:not(:first-of-type){
        margin-top: 17px;
    }
}

.article__author{
    display: flex;
    align-items: center;
    > i {
        background-color: #777777;
        height: 2px;
        width: 2px;
        border-radius: 50%;
        margin: 0 4px;
    }
    .article__author__date{
        color: #777777;
        font-size: 12px;
        font-weight: 500;
    }
}

.article__main{
    display: flex;
    align-items: flex-start;
    text-decoration: none;
    margin-top: 12px;
    .article__main__left{
        .article__title , p{
            transition: 200ms;
        }
        .article__title{
            max-height: 48px;
            overflow: hidden;
        }
    }
    &:hover , &:focus{
        .article__main__left{
            .article__title , p{
                transform: translateX(4px);
            }
        }
    }
}

.article__main__right{
    margin-left: auto;
    width: 102px;
    flex: 0 0 102px;
    .article__image{
        width: 100%;
    }
}

.article__main__left{
    padding-right: 20px;
    .article__title, p {
        color: #121212;
    }
    .article__title{
        font-weight: 600;
        font-size: 20px;
    }
    p{
        font-size: 16px;
        line-height: 19px;
        margin-top: 8px;
        max-height: 72px;
        overflow: hidden;
        display: inline-block;
    }
}

.article__tags{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    padding-right: 20px;
    > svg{
        width: 16px;
        flex: 0 0 16px;
    }
    .article__tag{
        display: inline-flex;
        border: 1px solid #D9D9D9;
        border-radius: 20px;
        padding: 4px 8px;
        font-weight: 500;
        font-size: 12px;
        color: #777777;

        &.article__tag--large{
            font-size: 16px;
            font-weight: 600;
            padding: 7px 24px;
        }
    }
}

.article__info{
    margin-top: 35px;
    & , .article__info__right{
        display: flex;
        align-items: center;
    }
    .article__info__right{
        margin-left: auto;
        gap: 14px;
        justify-content: flex-end;
    }
    .btn--grey{
        padding: 5px 11px;
        span{
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            display: inline-block;
            color: #121212;
        }
    }
    .article__views{
        display: flex;
        align-items: center;
        flex-direction: row;
        white-space: nowrap;
        b{
            font-weight: 500;
            font-size: 12px;
        }
        svg{
            margin-right: 8px;
            flex: 0 0 15px;
            width: 15px;
        }
    }
}

.filterbar{
    grid-column: span 2;
}

.filterbar__inner{
    display: flex;
    align-items: center;
    gap: 16px;
}

.menu--category{
    .btn b{
        display: inline-block;
        font-weight: 500;
        color: white;
        padding: 2px 5px;
        border-radius: 50%;
        margin-left: 8px;
        font-size: 10px;
        background-color: #000000;
    }
}

.filter-tags{
    display: flex;
    gap: 16px;
    & , .filter-tag{
        display: flex;
        align-items: center;
    }
    .filter-tag{
        background-color: #121212;
        height: 33px;
        line-height: 33px;
        padding: 0 24px;
        border-radius: 12px;
        .btn--black{
            padding: 0;
            width: 20px;
            height: 20px;
            justify-content: center;
            margin-right: 8px;
        }
        svg{
            margin-right: 0;
        }
        span{
            color: #F5F5F5;
            font-weight: 500;
            font-size: 14px;
            display: inline-block;
        }
    }
}



// ----------------
// Article skeleton
// ----------------
.skeleton-bg{
    background-color: #F5F5F5;
    border-radius: 10px;
}
.article-skeleton:not(:first-of-type){
    margin-top: 17px;
}
.article-skeleton__top{
    display: flex;
    align-items: center;
}
.article-skeleton__top__avatar{
    height: 24px;
    width: 24px;
    border-radius: 50%;
}
.article-skeleton__top__name , .article-skeleton__top__date{
    height: 15px;
    margin-left: 8px;
}
.article-skeleton__top__name{
    width: 153px;
}
.article-skeleton__top__date{
    width: 40px;
}
.article-skeleton__main{
    margin-top: 12px;
}
.article-skeleton__main__title{
    height: 48px;
}
.article-skeleton__main__desc{
    height: 58px;
    margin-top: 8px;
}
.article-skeleton__main__image{
    flex: 0 0 102px;
    height: 95px;
    width: 102px;
    margin-left: 8px;
}
.article-skeleton__main{
    display: grid;
    grid-template-columns: auto 110px;
}
.article-skeleton__bottom{
    margin-top: 35px;
    display: flex;
    align-items: center;
}
.article-skeleton__bottom__tag , .article-skeleton__bottom__shareBtn , .article-skeleton__bottom__views{
    height: 23px;
}
.article-skeleton__bottom__tag{
    width: 101px;
}
.article-skeleton__bottom__shareBtn{
    width: 74px;
    margin-left: auto;
}
.article-skeleton__bottom__views{
    width: 82px;
    margin-left: 14px;
}
.article-skeleton__line{
    margin-top: 35px;
    height: 2px;
    width: 100%;
}
// 

.page-content--article-page .container{
    position: relative;
}

.article__top{
    display: flex;
    align-items: center;
    .author{
        margin-top: 0;
        margin-left: 24px;
    }
    & , .article__top__left , .article__info__right , .article__views{
        display: flex;
        align-items: center;
    }
    .article__top__left{
        > i {
            background-color: #777777;
            width: 2px;
            height: 2px;
            border-radius: 2px;
            margin: 0 4px;
        }
    }
    .article__info__right{
        margin-left: auto;
        gap: 14px;
        > .btn--grey{
            height: 24px;
            line-height: 24px;
            padding: 4px 8px;
        }
        .number-of-views{
            font-weight: 500;
            font-size: 12px;
            margin-top: -1px;
        }
        .article__views svg{
            margin-right: 8px;
        }
    }
    .article__author__date{
        color: #777777;
        font-size: 12px;
        font-weight: 500;
    }
}

.article__tags-and-share , .article__tags-and-share__right{
    display: flex;
    align-items: center;
}
.article__tags-and-share{
    margin: 67px 0 50px;
    position: relative;
    &::before{
        content: '';
        display: block;
        position: absolute;
        height: 1px;
        width: 100%;
        background-color: #D9D9D9;
        bottom: -25px;

    }
}
.article__tags-and-share__left .article__tags{
    grid-gap: 16px;
}
.article__tags-and-share__right{
    margin-left: auto;
    padding-left: 20px;
    .article__views{
        display: none;
    }
    .btn--grey{
        padding-top: 10px;
        padding-bottom: 10px;
        svg{
            flex: 0 0 18px;
            width: 18px;
            height: 20px;
            margin-right: 11px;
        }
        span{
            font-size: 16px;
            font-weight: 500;
        }
    }
}

.panel--article{
    padding-top: 20px;
    .article__views--mobile{
        display: none;
    }
}

.panel--article{
    .article__title , .article__body{
        text-align: left;
    }
    .article__title{
        font-weight: 600;
        font-size: 48px;
        line-height: 58px;
        margin-top: 24px;
    }
    .article__author{
        align-items: flex-start;
        .h6{
            font-size: 16px;
            font-weight: 500;
        }
        .author__img__container{
            flex: 0 0 48px;
            height: 48px;
            width: 48px;
        }
        .author__name{
            margin-left: 8px;
            font-size: 16px;
            font-weight: 500;
        }
    }
    .article__author__left{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
    .article__author__right{
        margin-left: 100px;
        flex: 1 1 auto;
        p{
            font-size: 12px;
            font-weight: 400;
            color: #777777;
            line-height: 15px;
            margin-top: 16px;
        }
    }
    .article__abstract{
        margin-top: 16px;
        display: block;
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
    }
    .article__story{
        margin-top: 32px;
    }
.youtube-embed {
    height: 450px !important;
    width: calc(100% - 40px) !important;
    margin: 20px;
}
}

.panel--article .article__story{
    h1 , h2 , h3 , h4 , h5 , h6{
        text-align: left;
        color: #DE6B96;
        font-weight: 400;
    }
    h1{
        font-size: 48px;
        line-height: 58px;
        margin: 24px 0;
    }
    h2{
        font-size: 40px;
        line-height: 50px;
        margin: 24px 0;
    }
    h3{
        font-size: 36px;
        line-height: 46px;
        margin: 22px 0;
    }
    h4{
        font-size: 32px;
        line-height: 42px;
        margin: 22px 0;
    }
    h5{
        font-size: 28px;
        line-height: 36px;
        margin: 20px 0;
    }
    h6{
        font-size: 24px;
        line-height: 32px; 
        margin: 20px 0;
    }
    p , b , span , a , strong , i{
        font-size: 20px;
        font-weight: 400;
        line-height: 32px;
    }
    small{
        font-size: 16px;
        font-weight: 400;
        color: #777777;
    }
    strong{
        font-weight: 700;
    }
    p{
        margin: 24px 0;
    }
    ul , ol{
        padding: 0 20px;
        li{
            margin: 5px 0;
        }
    }
    img{
        width: 100%;
    }
    a{
        text-decoration: none;
        color: #DE6B96;
        font-weight: 700;
        &:hover , &:focus{
            color: #ff9bc0;
        }
    }
}

.article__tags--mobile{
    display: none;
}

@media(max-width: 1280px){
    .page-content--article-page{
        .btn.btn--back{
            display: none;
        }
    }
}

@media(max-width: 1050px){
    .page-content--article-page .container{
        padding-left: var(--containerPadding);
        padding-right: var(--containerPadding);
    }
}

@media(max-width: 900px){
    .panel--homepageCompressedArticles{
        .panel__heading{
            padding-top: 24px;
        }
        .panel__body{
            grid-template-columns: 1fr 1fr;
            grid-gap: 24px;
            padding-bottom: 24px;
        }
    }
    .panel--article{
        padding-top: 0;
        .article__title{
            font-size: 34px;
            line-height: 44px;
        }
        .article__abstract{
            font-size: 21px;
            line-height: 31px;
        }
        .article__story{
            margin-top: 24px;
            h1{
                font-size: 34px;
                line-height: 44px;
            }
            h2{
                font-size: 32px;
                line-height: 42px;
            }
            h3{
                font-size: 30px;
                line-height: 40px;
            }
            h4{
                font-size: 26px;
                line-height: 36px;
            }
            h5{
                font-size: 24px;
                line-height: 32px;
            }
            h6{
                font-size: 20px;
                line-height: 28px;
            }
            p , b , span , a , strong , i , li{
                font-size: 16px;
                line-height: 24px;
            }
        }
        .article__author .author__img__container {
            flex: 0 0 32px;
            width: 32px;
            height: 32px;
        }
        .article__author__left{
            min-width: 140px;
        }
        .article__author__right{
            margin-left: 0;
            padding-left: 24px;
        }
        .article__author{
            max-width: 100%;
        }
    }
    .article__tags-and-share__left .article__tags{
        grid-gap: 8px;
    }
    .article__tags .article__tag.article__tag--large{
        font-size: 14px;
    }
    .article__tags-and-share__right{
        .btn--grey{
            border-radius: 23px;
            span{
                font-size: 14px;
                font-weight: 500;
                display: inline-block;
                margin-top: -2px;
            }
        }
    }
    .article{
        padding-bottom: 0;
    }
    .container.container--newsletter{
        padding: 42px var(--containerPadding);
    }
    .panel--newsletter{
        h4{
            font-size: 16px;
            line-height: 21px;
        }
        .btn--pink{
            height: 56px;
            padding: 0 32px;
            border-radius: 16px;
            span{
                font-size: 16px;
                font-weight: 600;
            }
        }
    }
}

@media(max-width: 650px){
    .panel--article{
        .article__tags{
            display: none;
        }
        .article__top .author{
            margin-left: 0;
        }
        .article__title{
            font-size: 24px;
            line-height: 29px;
        }
        .article__abstract{
            margin-top: 16px;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
        }
        .article__story{
            margin-top: 16px;
            h1 , h2 , h3 , h4 , h5 , h6{
                margin: 16px 0;
            }
            h1{
                font-size: 26px;
                line-height: 36px;
            }
            h2{
                font-size: 25px;
                line-height: 35px;
            }
            h3{
                font-size: 24px;
                line-height: 34px;
            }
            h4{
                font-size: 23px;
                line-height: 33px;
            }
            h5{
                font-size: 22px;
                line-height: 32px;
            }
            h6{
                font-size: 20px;
                line-height: 30px;
            }
            p{
                margin: 16px 0;
            }
        }
        .article__author{
            flex-direction: column;
            .h6{
                font-size: 20px;
                font-weight: 500;
            }
        }
        .article__author__right{
            padding-left: 0;
            max-width: auto;
            .h6{
                display: none;
            }
        }
    }
    .article__top{
        flex-direction: column;
        align-items: flex-start;
        .article__info__right{
            margin-left: 0;
            margin-top: 16px;
        }
        .article__top__left > i{
            margin: 0 6px -2px;
        }
        .article__info__right{
            > .btn--grey{
                padding: 0 9px;
                svg{
                    margin-right: 10px;
                }
                span{
                    font-size: 14px;
                    font-weight: 500;
                    margin-top: -2px;
                }
            }
            .number-of-views{
                font-size: 14px;
                font-weight: 400;
            }
        }
        .article__author__date{
            font-size: 14px;
            font-weight: 400;
        }
        .article__views{
            svg path{
                fill: #5A5A5A;
            }
            b{
                color: #5A5A5A;
            }
        }
    }
    .author .author__name{
        font-size: 14px;
        font-weight: 400;
    }
    .article__tags--mobile{
        display: block;
        margin-top: 24px;
        padding: 24px 0;
        border-top: 1px solid #D9D9D9;
        border-bottom: 1px solid #D9D9D9;
        .h6{
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            color: #121212;
        }
        .article__tag{
            color: #5A5A5A;
            padding: 8px 24px;
            border: 1px solid #5A5A5A;
            border-radius: 16px;
        }
    }
    .article__tags__body{
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 8px;
        margin-top: 12px;
    }
    .panel.panel--homepageCompressedArticles{
        margin-top: 48px;
    }
    .panel--homepageCompressedArticles{
        .panel__body{
            padding-bottom: 72px;
            grid-template-columns: 1fr;
            gap: 36px;
        }
    }
    .page-content__inner--landing .panel--homepageCompressedArticles .panel__body{
        padding-bottom: 10px;
    }
    .panel--homepageCategories{
        .authors--container{
            width: 100%;
        }
        .author{
            .author__img__container{
                flex: 0 0 36px;
                width: 36px;
                height: 36px;
            }
            .author__name{
                font-size: 14px;
                font-weight: 500;
            }
        }
    }
}

@media(min-width: 600px){
    .page-content--homepage .article .article__views--mobile{
        display: none;
    }
}

@media(max-width: 600px){
    .article__main{
        .article__main__left{
            padding-right: 0;
            .article__title , p{
                max-height: none;
            }
        }
        .article__main__right{
            display: none;
        }
    }
    .article{
        &:not(:first-of-type){
            margin-top: 36px;
        }
        .article__author{
            > i , .article__author__date{
                display: none;
            }
        }
        .article__info{
            margin-top: 22px;
            flex-wrap: wrap;
            .article__tags , .article__info__right{
                padding: 4px 0;
            }
        }
        .author .author__name{
            font-size: 12px;
        }
    }
    .article__main .article__main__left{
        .article__title{
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
        }
        p{
            display: none;
        }
    }
    .page-content__inner.page-content__inner--homepage{
        .article__inner{
            padding-bottom: 17px;
        }
        .page-sidebar .page-sidebar__section:not(:first-of-type){
            margin-top: 48px;
        }
    }
    .article__tags .article__tag{
        border-color: #5A5A5A;
        padding: 4px 12px;
    }
    .page-sidebar{
        .page-sidebar__section__heading{
            margin-bottom: 24px;
        }
        .author .author__img__container{
            flex: 0 0 36px;
            width: 36px;
            height: 36px;
        }
        .page-sidebar__section--support{
            .btn{
                padding: 8px 24px;
                span{
                    font-size: 14px;
                }
            }
            .page-sidebar__section__body{
                gap: 9px;
            }
        }
    }
    .page-content--homepage .article{
        .article__views{
            display: none;
        }
        .article__views--mobile{
            display: flex;
            align-items: center;
            b , i {
                display: inline-block;
            }
            svg{
                margin-right: 9px;
            }
            i{
                background-color: #5A5A5A;
                height: 2px;
                width: 2px;
                border-radius: 2px;
                margin: 0px 5px 0 4px;
            }
            .number-of-views{
                color: #5A5A5A;
                font-size: 12px;
                line-height: 16px;
                font-weight: 400;
                white-space: nowrap;
            }
        }
    }
}

@media(max-width: 500px){
    .article__tags--mobile .article__tag{
        font-size: 14px;
        font-weight: 500;
        padding: 7px 24px;
    }
}

@media(max-width: 390px){
    .container.container--newsletter{
        padding-top: 24px;
        padding-bottom: 24px;
    }
    .panel--newsletter .btn--pink{
        flex: 1 1 auto;
        width: 100%;
        justify-content: center;
        height: 48px;
        padding: 0 20px;
        svg{
            width: 18px;
        }
        span{
            font-size: 15px;
        }
    }
    .article .article__info{
        flex-direction: column;
        align-items: flex-start;
        .article__tags , .article__info__right{
            padding: 0;
        }
        .article__info__right{
            margin-left: 0;
            margin-top: 14px;
        }
    }
}