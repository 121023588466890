*{
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: 'Inter', sans-serif !important;
}

button{
    font-family: 'Inter', sans-serif !important;
}

a{
    transition: 200ms;
    text-decoration: none;
}

h1 , h2 , h3 , h4 , h5 , h6 , p{
    margin: 0;
}

html{
    height: 100vh;
}

#nea-app , #root, body{
    height: 100%;
}

#nea-app{
    position: relative;
    display: flex;
    flex-direction: column;
}

.page-content{
    padding-top: 166px;
}

.panel{
    &:not(:first-of-type){
        margin-top: calc(var(--spacing) * 2);
    }
    .panel__heading{
        padding: 24px 24px 16px 0;
        .h3{
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
        }
    }
}

.cloak{
    position: fixed;
    top: 0; left: 0;
    height: 100%;
    width: 100%;
    background-color: white;
    z-index: 999;
    transition: 700ms;
    pointer-events: none;
    user-select: none;

    visibility: invisible;
    opacity: 0;
    &.cloak--active{
        visibility: visible;
        opacity: 100;
    }
}

.menu{
    position: relative;
    .menu__dd{
        position: absolute;
        top: calc(100%);
        transition: 200ms;

        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        user-select: none;
    }
    &.menu--open{
        .menu__dd{
            top: calc(100% + 9px);

            opacity: 100;
            visibility: visible;
            pointer-events: auto;
            user-select: auto;
        }
    }
}

.menu__dd{
    background-color: #F5F5F5;
    border-radius: 8px;
    padding: 10px 0;
    z-index: 10;
    .menu-link{
        color: #121212;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        display: block;
        text-decoration: none;
        padding: 10px 24px;
        border: none;
        cursor: pointer;
        background-color: #F5F5F5;
        transition: 200ms;
        &:hover , &:focus{
            background-color: #eeeeee;
        }
        &.menu-link--active{
            background-color: #d8d8d8;
            font-weight: 600;
        }
    }
}

.menu--sort{
    .menu__dd{
        min-width: 130px;
        &::before{
            content: '';
            display: block;
            position: absolute;
            height: 0; width: 0;
            top: -7px; left: 41px;

            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #F5F5F5;
        }
        .menu-link{
            width: 100%;
            text-align: left;
        }
    }
}

.menu--category{
    .menu__dd{
        min-width: 387px;
        &::before{
            content: '';
            display: block;
            position: absolute;
            height: 0; width: 0;
            top: -7px; left: 61px;

            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #F5F5F5;
        }
    }
    .menu__dd__body{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}

.menu--share{
    .menu__dd{
        padding: 10px;
        left: unset;
        right: 0;
        .btn{
            background-color: #ebebeb;
            justify-content: center;
            flex: 0 0 40px;
            width: 40px;
            height: 40px;
            padding: 10px;
            border-radius: 6px;
            &:hover , &:focus{
                svg{
                    transform: scale(1.2);
                }
            }
            svg{
                width: 20px;
                height: 20px;
                margin: 0;
                transition: 400ms;
            }
        }
        .btn.btn--share--x{
            svg{
                flex: 0 0 16px;
                width: 16px;
                height: 16px;
            }
        }
    }
    .share-buttons-container{
        display: grid;
        grid-template-columns: 40px 40px 40px 40px;
        grid-gap: 10px;
    }
}

.selectedCats-container{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
}

.page-content__inner--newsletter{
    background-color: #F5F5F5;
}

.panel--newsletter{
    text-align: center;
    h4{
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #121212;
    }
    .btn--pink{
        margin: 24px auto 0;
        height: 72px;
        padding: 0 48px;
        span{
            display: inline-block;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
        }
    }
}

.container.container--newsletter{
    max-width: 926px;
    padding: 72px 0;
}

#ndzn-footer{
    margin-top: auto;
    min-height: 132px;
    .container{
        text-align: center;
        padding-top: 40px;
        padding-bottom: 72px;
        span{
            color: #777777;
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
            display: inline-block;
        }
    }
}

input , .input{
    background-color: #F5F5F5;
    border: 0px;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    padding: 24px 16px;
    border-radius: 12px;
    outline: none;
    &::placeholder{
        color: #121212;
    }
}

.form__error{
    background-color: #fd5c5c;
    border-radius: 10px;
    color: white;
    font-weight: 600;
    padding: 12px 20px;
    margin-bottom: 16px;
}

@media(max-width: 900px){
    .panel:not(:first-of-type){
        margin-top: 24px;
    }
}

@media(min-width: 650px){
    .footer__mobile-links{
        display: none;
    }
}

@media(max-width: 650px){
    ul, ol{
        margin: 24px 0 !important;
    }
    #ndzn-footer{
        .container{
            display: flex;
            flex-direction: column;
            padding: 72px 24px;
            > span {
                margin-top: 8px;
                display: inline-block;
            }
            span{
                text-align: left;
            }
        }
        .footer__mobile-links{
            display: inline-flex;
            gap: 8px;
            border-bottom: 1px solid #D9D9D9;
        }
        .footer__mobile-link{
            padding: 8px 0;
            display: flex;
            align-items: center;
            span{
                display: inline-block;
                margin-top: 0;
            }
        }
    }
}

@media(max-width: 600px){
    .menu--category .menu__dd{
        min-width: 300px;
    }
    .menu__dd{
        padding: 6px 0;
        .menu-link{
            padding: 8px 14px;
            font-size: 12px;
            line-height: 15px;
        }
    }
}

@media(max-width: 490px){
    .menu--category .menu__dd{
        left: -112px;
        &::before{
            left: 181px;
        }
    }
}