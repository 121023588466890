.article.article--compressed:not(:first-of-type){
    margin-top: 0;
}

.article--compressed{
    text-decoration: none;
    border-bottom: none !important;
    &:hover , &:focus{
        transform: scale(1.03);
    }
    span{
        display: block;
    }
    .article--compressed__img-container{
        width: 243px;
        height: 152px;
        background-color: #F5F5F5;
        img{
            height: 100%;
            display: block;
            margin: 0 auto;
        }
    }
    .article--compressed__author-info{
        display: flex;
        align-items: center;
        margin-top: 10px;
        .article--compressed__author-image{
            width: 24px;
            height: 24px;
            margin-right: 8px;
            border-radius: 50%;
        }
        .article--compressed__author-name{
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
            max-width: 153px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: #121212;
        }
        i{
            display: inline-block;
            width: 2px;
            height: 2px;
            background-color: #777777;
            border-radius: 50%;
            margin: 0 6px;
        }
        .article--compressed__date{
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
            color: #777777;
        }
    }
    .article--compressed__title{
        margin-top: 12px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        height: 40px;
        overflow: hidden;
        color: #121212;
    }
    .article--compressed__description{
        margin-top: 8px;
        color: #777777;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        height: 30px;
        overflow: hidden;
    }
    .article--compressed__info{
        display: flex;
        align-items: center;
        margin-top: 16px;
    }
    .article--compressed__tag{
        border: 1px solid #F5F5F5;
        color: #777777;
        padding: 4px 8px;
        font-size: 12px;
        border-radius: 16px;
    }
    .article--compressed__vip{
        margin-bottom: -3px;
        margin-right: 12px;
    }
    .article--compressed__views{
        flex: 0 0 90px;
        margin-left: auto;
        display: flex;
        align-items: center;
        svg{
            margin-right: 8px;
        }
        span{
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #121212;
        }
    }
}

@media(max-width: 900px){
    .article--compressed .article--compressed__img-container{
        width: auto;
    }
}

@media(max-width: 650px){
    .article.article--compressed{
        position: relative;
        padding-bottom: 16px;
        border-bottom: 1px solid #D9D9D9 !important;
        .article--compressed__img-container{
            display: none;
        }
        .article--compressed__author-info{
            margin-top: 0;
            i , .article--compressed__date{
                display: none;
            }
        }
        .article--compressed__title{
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
        }
        .article--compressed__tag{
            border-color: #5A5A5A;
            color: #5A5A5A;
        }
        .article--compressed__tags{
            padding-right: 16px;
        }
    }
}