.page-sidebar__section__heading{
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    margin-bottom: 16px;
}

.page-sidebar__section:not(:first-of-type){
    margin-top: 48px;
}

.page-sidebar__section__body{
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;
    .category{
        background-color: #F5F5F5;
        border-radius: 12px;
        font-weight: 500;
        font-size: 14px;
        padding: 8px 12px;
    }
}

.page-sidebar__section--authors .page-sidebar__section__body{
    flex-direction: column;
    gap: 0;
    display: inline-block;
    .author-container{
        cursor: pointer;
        &:not(:first-of-type){
            margin-top: 16px;
        }
    }
}

.page-sidebar__section--support{
    .btn span{
        font-size: 16px;
        font-weight: 600;
    }
}

.author-skeleton {
    font-size: 0;
    display: flex;
    align-items: center;
    &:not(:first-of-type){
        margin-top: 16px;
    }
    .author-skeleton__img , .author-skeleton__name{
        background-color: #F5F5F5;
        display: inline-block;
    }
    .author-skeleton__img{
        height: 24px;
        width: 24px;
        border-radius: 50%;
    }
    .author-skeleton__name{
        width: 80px;
        height: 14px;
        border-radius: 6px;
        margin-left: 8px;
    }
}