.modal{
    position: fixed;
    top: 0; left: 0;
    z-index: 9999;
    height: 100%;
    width: 100%;
    transition: 300ms;
    &.modal--closed{
        visibility: hidden;
        opacity: 0;
        .modal__wrap{
            background-color: transparent;
        }
        &:not(.modal--side-left){
            .modal__dialogue{
                transform: translateY(15px);
            }
        }
        &.modal--side-left{
            .modal__dialogue{
                transform: translateX(-15px);
            }
        }
    }
    &.modal--open{
        visibility: visible;
        opacity: 1;
        .modal__wrap{
            background-color: #000000a3;
        }
        .modal__dialogue{
            transform: translateY(0px);
        }
    }
    &.modal--side-left{
        .modal__wrap{
            padding: 0;
        }
        .modal__dialogue{
            margin: 0;
            border-radius: 0;
            height: 100%;
            max-width: unset;
            width: 350px;
            padding: 24px;
            display: block;
        }
    }

    .modal__wrap{
        height: 100%;
        width: 100%;
        display: flex;
        transition: 300ms;
        position: relative;
        z-index: 1;
        padding: 0 24px;
    }
    .modal__dialogue{
        background-color: white;
        overflow: hidden;
        position: absolute;
        margin: auto;
        max-width: 1200px;
        width: 100%;
        border-radius: 10px;
        height: 617px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 3;
        top: 0; bottom: 0; left: 0; right: 0;
        margin: auto;
        transition: 350ms;
        padding: 40px;
        .btn--close{
            background: transparent;
            padding: 10px;
            position: absolute;
            top: 20px; right: 20px;
            transition: 400ms;
            &:hover , &:focus{
                transform: scale(1.25);
            }
            svg{
                margin: 0;
            }
        }
        &.modal__dialogue--loading{
            .loading-el{
                visibility: visible;
                opacity: 1;
            }
        }
        .loading-el{
            position: absolute;
            visibility: hidden;
            opacity: 0;
            top: 0; left: 0;
            width: 100%;
            height: 100%;
            background-color: white;
            transition: 350ms;
            z-index: 100;
        }
        .btn--back{
            top: 120px;
            left: 125px;
        }
    }
    .modal__title , .modal__subtitle{
        display: block;
        color: #121212;
    }
    .modal__title{
        font-weight: 500;
        font-size: 48px;
        line-height: 58px;
    }
    .modal__subtitle{
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        margin-top: 24px;
        text-align: center;
    }
    .form--email{
        margin-top: 60px;
    }
    .modal-doodle{
        pointer-events: none;
        user-select: none;
        position: absolute;
    }
    .btn--home{
        text-decoration: none;
        padding: 13px 45px;
        margin-top: 48px;
        span{
            font-size: 14px;
            font-weight: 600;
        }
    }
    .modal-doodle--1{
        bottom: 0; left: 0;
        width: 190px;
    }
    .modal-doodle--2{
        top: 0; left: 175px;
        margin: 0 auto;
        width: 190px;
    }
    .modal-doodle--3{
        bottom: 56px; right: 211px;
        width: 106px;
    }
    .modal-doodle--4{
        width: 180px;
        top: 0; right: 0;
    }
}

.form--email{
    width: 442px;
    input{
        width: 100%;
    }
    .btn--pink , .btn--transparent{
        height: 41px;
        padding: 0 49px;
        margin: 0;
        width: 100%;
        justify-content: center;
        span{
            font-size: 14px;
            line-height: 17px;
            display: inline-block;
        }
    }
    .form__buttons{
        padding: 0 49px;
        margin-top: 48px;
    }
    .btn--transparent{
        margin-top: 16px;
    }
}



.modal.modal--mobile-menu{
    .modal__dialogue{
        .btn.btn--close{
            display: block;
            top: 28px; right: 28px;
        }
    }
    h2{
        margin: 0;
        color: #AE64AC;
        font-size: 32px;
    }
    .menu__body{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }
    .mobile-menu__link{
        font-size: 18px;
        font-weight: 500;
        padding: 12px 50px;
        display: flex;
        align-items: center;
        position: relative;
        &:hover , &:focus{
            span{
                transform: translateX(5px);
            }
        }
        &:not(:first-of-type){
            margin-top: 16px;
        }
        span{
            color: #121212;
            transition: 200ms;
        }
        svg{
            width: 34px;
            position: absolute;
            left: 0; top: 0;
            bottom: 0;
            margin: auto 0;

        }
    }
    .mobile-menu__doodle-container{
        position: absolute;
        overflow: hidden;
        z-index: -1;
        pointer-events: none;
        user-select: none;
        opacity: 0.08;
        img{
            position: relative;
            width: 100%;
        }
    }
    .mobile-menu__doodle-container--1{
        width: 200px;
        top: 0; left: 0;
        img{
            top: -50px;
            left: -50px;
        }
    }
    .mobile-menu__doodle-container--2{
        width: 150px;
        top: 340px;
        right: 0px;
        img{
            right: -20px;
        }
    }
    .mobile-menu__doodle-container--3{
        width: 180px;
        top: 650px; left: 0;
        img{
            left: -60px;
        }
    }
}

@media(min-width: 1361px){
    .modal .modal__dialogue .btn--close{
        display: none;
    }
}

@media(max-width: 1360px){
    .modal.modal--newsLetter{
        .btn--back{
            display: none;
        }
    }
}

@media(max-width: 1270px){
    .modal .modal__dialogue{
        max-width: none;
        width: calc(100% - 48px);
        margin: auto 24px;
    }
}

@media(max-width: 900px){
    .modal{
        .modal__title{
            font-size: 38px;
            line-height: 48px;
        }
        .modal__subtitle{
            font-size: 18px;
            margin-top: 16px;
        }
        .modal-doodle--2{
            top: -20px;
            left: -20px;
        }
        .modal-doodle--3{
            bottom: -15px;
            right: -15px;
        }
    }
}

@media(max-width: 750px){
    .modal{
        .btn--home{
            height: 43px;
            margin-top: 72px;
            border-radius: 12px;
            padding: 13px 43px;
            span{
                font-size: 16px;
            }
        }
    }
}

@media(min-width: 650px){
    .modal-doodle-mobile{
        display: none;
    }
}

@media(max-width: 650px){
    .modal{
        &.modal--newsLetter{
            .modal__heading , .modal__body{
                width: 100%;
            }
            .form--email{
                width: unset;
                margin-top: 24px;
                input{
                    padding: 15px 16px;
                    border-radius: 12px;
                    font-size: 16px;
                    font-weight: 500;
                }
                .btn{
                    border-radius: 12px;
                }
                .btn--transparent{
                    background: none;
                    width: unset;
                    margin: 16px auto 0;
                }
            }
            .form__buttons{
                padding: 0;
                margin-top: 65px;
            }
            .btn--home{
                margin: 72px auto 85px;
            }
        }
        .modal__dialogue{
            padding: 72px 24px;
            align-items: flex-start;
            height: 527px;
        }
        .modal__title{
            font-size: 24px;
            line-height: 29px;
            font-weight: 500;
            text-align: left;
        }
        .modal__subtitle{
            text-align: left;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
        }
        .modal-doodle--1{
            width: 138px;
        }
        .modal-doodle--2{
            top: 0; left: -25px;
            width: 110px;
        }
        .modal-doodle--3{
            display: none;
        }
        .modal-doodle--4{
            display: none;
        }
        .modal-doodle-mobile{
            pointer-events: none;
            user-select: none;
            width: 88px;
            position: absolute;
            bottom: 111px; right: 0;
        }
    }
}

@media(max-width: 390px){
    .modal{
        &.modal--side-left{
            .modal__dialogue{
                width: 300px;
            }
        }
        &.modal--mobile-menu .mobile-menu__link{
            padding: 12px 40px;
            &:not(:first-of-type){
                margin-top: 12px;
            }
            span{
                font-size: 16px;
            }
            svg{
                width: 28px;
            }
        }
    }
}