.btn.header-button{
    transition: 300ms;
    font-size: 16px;
    padding: 10px 24px;
}

.container{
    max-width: 871px;
    margin: 0 auto;
}

#nea-header{
    position: fixed;
    top: 0; left: 0; right: 0;
    z-index: 14;
    background-color: white;
    &::before{
        content: '';
        display: block;
        position: absolute;
        bottom: -6px; left: 0;
        height: 10px; width: 100%;
        background: linear-gradient(to bottom, white, transparent);
    }
    .container{
        display: flex;
        align-items: center;
        padding: 48px var(--containerPadding);
        &.container--header{
            max-width: 1161px;
        }
    }
    .header-logo{
        width: 52px;
    }
    .header-main__links{
        margin-left: 38px;
        display: flex;
        gap: 28px;
    }
    .header-main__buttons{
        display: flex;
        align-items: center;
        gap: 24px;
        margin-left: auto;
    }
    .header-link{
        color: #121212;
        text-decoration: none;
        padding: 20px 5px;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        &:hover , &:focus{
            color: #474747;
        }
        &.header-link--active{
            color: #AE64AC;
            &:hover , &:focus{
                color: #AE64AC;
            }
        }
    }
    .header-main__search-bar-container{
        margin-left: 36px;
        display: flex;
        align-items: center;
        .searchBar{
            display: flex;
            align-items: center;
            position: relative;
            .btn.btn--transparent{
                position: absolute;
                left: 7px;
                padding: 0;
                width: 31px;
                height: calc(100% - 2px);
                border-radius: 50%;
                justify-content: center;
                background: transparent;
                svg{
                    margin-right: 0;
                }
            }
            input{
                background: #F5F5F5;
                border: 0px;
                height: 36px;
                line-height: 36px;
                width: 315px;
                border-radius: 12px;
                outline: none;
                padding: 0 12px 0 40px;
                font-size: 16px;
                font-weight: 500;
                line-height: 17px;
                &::placeholder{
                    color: #121212;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 17px;
                }
            }
        }
    }
}

@media(max-width: 1220px){
    #nea-header{
        .container{
            padding-top: 24px;
            padding-bottom: 24px;
        }
        .header-main__links , .header-main__search-bar-container{
            margin-left: 28px;
        }
        .header-main__links{
            gap: 20px;
        }
        .header-link{
            padding: 16px 5px;
            font-size: 15px;
        }
        .header-main__search-bar-container .searchBar{
            input{
                width: 215px;
                & , &::placeholder{
                    font-size: 14px;
                    color: #5A5A5A;
                }
            }
            .btn.btn--transparent svg{
                width: 14px;
            }
        }
        .header-main__buttons{
            gap: 28px;
        }
    }
    .btn.header-button{
        padding: 10px 16px;
        span{
            margin-bottom: 0px;
            font-size: 14px;
        }
    }
}

@media(max-width: 1000px){
    #nea-header{
        .header-logo{
            width: 36px;
        }
        .header-main__buttons{
            gap: 20px;
        }
        .header-main__links , .header-main__search-bar-container{
            margin-left: 20px;
        }
        .header-main__links{
            gap: 16px;
        }
    }
    .btn.header-button{
        svg{
            display: none;
        }
    }
}

@media(min-width: 900px){
    #nea-header .btn.btn--mobile-menu{
        display: none;
    }
}

@media(max-width: 900px){
    #nea-header{
        .header-main__search-bar-container{
            margin-left: auto;
        }
        .header-main__links , .header-main__buttons{
            display: none;
        }
        .btn.btn--mobile-menu{
            padding: 0;
            height: 36px;
            padding: 0 6px;
            background: transparent;
            margin-left: 6px;
            svg{
                transition: 200ms;
                margin-right: 0;
            }
            &:hover , &:focus{
                svg{
                    transform: scale(1.04);
                }
            }
        }
    }
}

@media(max-width: 500px){
    #nea-header .header-main__search-bar-container .searchBar input{
        width: 141px;
    }
}
